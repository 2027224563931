<template>
  <div class="m-5">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="mb-4">
      <el-breadcrumb-item :to="{ name: 'EditFaqHome' }"
        >Home</el-breadcrumb-item
      >
      <el-breadcrumb-item>Instructors FAQ</el-breadcrumb-item>
    </el-breadcrumb>
    <el-row>
      <el-col>
        <h2 class="mb-5">編輯 Instructors FAQ</h2>
        <div class="d-block">
          <div>
            <el-transfer
              v-model="resultValue"
              :titles="['FAQ', 'Instructors FAQ']"
              :props="{
                key: 'faq_id',
                label: 'desc'
              }"
              :data="faqListdata"
              target-order="push"
            ></el-transfer>
          </div>

          <div class="d-flex btns">
            <el-button type="primary" @click="handleBack">回到上一頁</el-button>
            <el-button type="primary" @click="handleSubmit">送出</el-button>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import _ from "lodash";

export default {
  props: ["getInstructorsFaq", "getFaqList"],
  data() {
    return {
      faqListdata: this.generateFaqData(),
      resultValue: this.getDefaultResultValue()
    };
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    generateFaqData() {
      let data = [];
      let faqList = this.getFaqList.faqs;
      for (let i = 0; i < faqList.length; i++) {
        data.push(faqList[i]);
        data[i].desc = faqList[i].question.tw;
        data[i].faq_id = faqList[i].id;
      }
      data = this.sortListData(data);
      return data;
    },
    sortListData(data) {
      data.sort((a, b) => (a.desc > b.desc ? 1 : b.desc > a.desc ? -1 : 0));
      return data;
    },
    getDefaultResultValue() {
      let result = [];
      let data = this.generateFaqData();
      if (this.getInstructorsFaq.length !== 0) {
        this.getInstructorsFaq.instructor_faqs.forEach(x => {
          data.forEach(y => {
            if (y.desc === x.question.tw) {
              result.push(y.faq_id);
            }
          });
        });
      }
      return result;
    },
    async handleSubmit() {
      let payload = {
        faqs: []
      };
      const data = this.generateFaqData();
      this.resultValue.forEach(i => {
        data.forEach(x => {
          if (x.id === i) {
            payload.faqs.push(x);
          }
        });
      });
      await this.$store.dispatch("instructors/postInstructorsFaq", payload);
      this.$router.push({
        name: "EditFaqHome"
      });
    },
    handleBack() {
      this.$router.push({
        name: "EditFaqHome"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.el-button {
  outline: none;
}

.btns {
  margin-top: 20px;
}
::v-deep .el-transfer-panel {
  width: 400px;
  margin-bottom: 40px;
}
</style>
